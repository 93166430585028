.container {
  
  padding: 0;
  overflow: hidden;

}

footer {
  
  text-align: center;

}

.sponsorMessage {

  margin-top: -8vh;
  color: rgba(152, 152, 152, 0.7);

}

.sponsorMessage a {
  color: var(--secondary);
  text-decoration: none;
}
  
  footer p {
    margin-bottom: 0;
  }

  .footerTitle{
    color: #5ACAFF;
    font-size: 2rem;
    margin-top: 10vh;
    margin-bottom: 3vh;
  }

  .col-md-6 {
    margin-bottom: auto;
    margin-top: auto;
  } 
  
  footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  footer ul li {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
  }

  .row {
    
    margin-top: 2vh;
    margin-bottom: 2vh;

  }

  .rowBottom {
    background-color: #00000083;
    box-shadow: 0px 0px 30px 30px #00000083;
    text-align: center;
    margin-top: 10vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .sponsor{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 50%;
    width: 90%;
    opacity: .7;
  }

  .sponsor:hover{
    transition: all 0.25s ease-in-out;
    opacity: 1;
  }

  .sponsorsRow{
    margin-top: -10vh;
    margin-left: 5vh;
    margin-right: 5vh;
    margin-bottom: 5vh;
  }

  .sponsors{
    margin-top: auto;
    margin-bottom: auto;
  }

  .mainFooter{
    display: flex;
    justify-content: center;
  }

  .mainFooter p{
    color: #acacac;
    font-size: 2vh;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mainFooter img{
    width: 10vh;
    margin-left: 3vh;
    margin-top: -3vh;
    margin-bottom: -3vh;
  }

  @media screen and (max-width: 991px){
  footer .navItem {
      font-size: 1.9vh;
      margin: 0;
  }

  .sponsorMessage {

    margin-top: 2vh;
    font-size: 3.5vw;
  
  }

  .missionSection{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 15vh;
  }

  .thankYouTitle{
    margin-top: 15vh;
  }

  .footerHeader{
    display: inline;
  }


  footer ul li {
    margin-right: 10px;
    margin-left: 10px;
  }

  .sponsorsRow{
    margin-left: 0vh;
    margin-right: 0vh;
    display: flex;
    margin-bottom: 5vh;
  }

  .col-md-6{
    margin-top: 0;
    margin-bottom: -3vh;
  }

  .footerTitle{
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-size: 2vh;
  }

  .row{
    padding: 0;
  }

  .sponsor{
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 10vh;
    opacity: .7;
  }

  .col{
    padding: 1vh;
  }

  .sponsors{
    flex-wrap: wrap;
    flex: 3;
  }

  .mainFooter p{
    color: #acacac;
    font-size: 1.5vh;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mainFooter img{
    width: 10vh;
    margin-left: 1vh;
    margin-top: -3vh;
    margin-bottom: -3vh;
  }

}