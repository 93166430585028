.item {

    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 20vh;
    height: 30vh;
    padding: 1vh;
    text-align: center;
    color: #FFF;
    border-radius: 10px;
    box-shadow: rgb(0, 0, 0) 0 0 15px;
    background: rgba(120, 120, 120, 0.5);
    border-top:rgb(125, 125, 125) 1px solid;
    border-left:rgb(125, 125, 125) 1px solid;
    border-bottom:rgb(40, 40, 40) 1px solid;
    border-right:rgb(40, 40, 40) 1px solid;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    transition: all 1s ease;

}

.item:hover {

    box-shadow: rgb(0, 0, 0) 0 10px 25px;

}

.item .cardImage {

    border-radius: 10px;
    width: 16vh;
    height: 16vh;
    margin: 2vh;
    align-self: center;

    transition: all 0.5s ease;

}

.item:hover .cardImage {

    border-radius: 10px;
    width: 16vh;
    height: 16vh;
    margin: 2vh;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0  8px 0;

}

.item .backgroundImage {

    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    filter: blur(50px);

}

.item:hover h3 { color: rgba(255, 255, 255, 0.9); text-shadow: rgba(0, 0, 0, 0.5) 0 0 10px; }

.item h3 {

    font-size: 1.75vh;
    margin: 0;
    padding: 0;
    color: #FFF;
    transition: all 0.5s ease;

}

.item:hover p { color: rgba(255, 255, 255, 0.7); text-shadow: rgba(0, 0, 0, 0.5) 0 0 10px; }

.item p {

    font-size: 1.15vh;
    margin: 0;
    margin-bottom: 1vh;
    padding: 0;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--norm);
    transition: all 0.5s ease;

}

.cardText {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.cardLinks {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 55%;

}

.a { transform: rotateY(0deg) translateZ(var(--z-spacing)); }
.a:hover { transform: rotateY(0deg) translateZ(var(--z-spacing)) scale(1.05); }

.b { transform: rotateY(72deg) translateZ(var(--z-spacing)); }
.b:hover { transform: rotateY(72deg) translateZ(var(--z-spacing)) scale(1.05); }

.c { transform: rotateY(144deg) translateZ(var(--z-spacing)); }
.c:hover { transform: rotateY(144deg) translateZ(var(--z-spacing)) scale(1.05);  }

.d { transform: rotateY(216deg) translateZ(var(--z-spacing)); }
.d:hover { transform: rotateY(216deg) translateZ(var(--z-spacing)) scale(1.05); }

.e { transform: rotateY(288deg) translateZ(var(--z-spacing)); }
.e:hover { transform: rotateY(288deg) translateZ(var(--z-spacing)) scale(1.05); }
/* 
.f { transform: rotateY(120deg) translateZ(var(--z-spacing)); }
.f:hover { transform: rotateY(120deg) translateZ(var(--z-spacing)) scale(1.05); }

.g { transform: rotateY(144deg) translateZ(var(--z-spacing)); }
.g:hover { transform: rotateY(144deg) translateZ(var(--z-spacing)) scale(1.05); }

.h { transform: rotateY(168deg) translateZ(var(--z-spacing)); }
.h:hover { transform: rotateY(168deg) translateZ(var(--z-spacing)) scale(1.05); }

.i { transform: rotateY(192deg) translateZ(var(--z-spacing)); }
.i:hover { transform: rotateY(192deg) translateZ(var(--z-spacing)) scale(1.05); }

.j { transform: rotateY(216deg) translateZ(var(--z-spacing)); }
.j:hover { transform: rotateY(216deg) translateZ(var(--z-spacing)) scale(1.05); }

.k { transform: rotateY(240deg) translateZ(var(--z-spacing)); }
.k:hover { transform: rotateY(240deg) translateZ(var(--z-spacing)) scale(1.05); }

.l { transform: rotateY(264deg) translateZ(var(--z-spacing)); }
.l:hover { transform: rotateY(264deg) translateZ(var(--z-spacing)) scale(1.05); }

.m { transform: rotateY(288deg) translateZ(var(--z-spacing)); }
.m:hover { transform: rotateY(288deg) translateZ(var(--z-spacing)) scale(1.05); }

.n { transform: rotateY(312deg) translateZ(var(--z-spacing)); }
.n:hover { transform: rotateY(312deg) translateZ(var(--z-spacing)) scale(1.05); }

.o { transform: rotateY(336deg) translateZ(var(--z-spacing)); }
.o:hover { transform: rotateY(336deg) translateZ(var(--z-spacing)) scale(1.05); } */
