/* .form-row>.col, .form-row>[class*=col-] {
    padding-right: 30px;
    padding-left: 5px;
} */
#registration{
    height: 100vh;
    padding-top: 10vh;
    /* overflow-x: hidden; */
}

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
}

label {
    margin-bottom: 0.5rem;
    /* background-image: var(--gradient);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; */
    color: white;
    font-family: var(--barlow);
}

.form-control {

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 2vh;
    line-height: 1.5;
    color: rgb(232, 232, 232);
    /* background-color: #2d2d2d34; */
    box-shadow: 0 0 4px 2px #00000034;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: text;

}

.form-group {
    margin-bottom: 1.5vh;
}


.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    width: 100%;
    margin-top: 2.5vh;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn {

    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;

    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.registrationSection {
    display: flex;
    text-align: left;
    align-self: center;
    margin: 0 auto;
    width: 80%;
}

.formSection {
    width: 50%;
    /* margin-left: 2vw; */
}

.paymentSection {
    width: 50%;
    margin-left: 9vw;;
    margin-top: 12vh;
    margin-right: 3vw;
    z-index: 999;
}

button {
    color: white;
}


/* #firstName {
    width: 50%;
}

#lastName {
    width: 50%;
} */

.labels {

    /* margin: 2vw; */
    background-image: var(--gradient);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; 
    /* animation: slideInFromBottom 1s; */
    /* text-align: left; */
}

.registrationSectionHeader {
    color: var(--gradient);
    font-family: var(--barlow);
    text-align: center;
    font-size: calc(3.5vh + 1.3vw);
    margin-bottom: 1vh;
}

.desc {

    color: #acacac;
    font-size: calc(1.25vh + 0.5vw);
    font-family: var(--norm);
    margin-top: auto;
    text-align: center;
    margin-bottom: -0.5vh;

}

.requirements {
    color: #acacac;
    font-size: calc(1.25vh + 0.5vw);
    font-family: var(--norm);
    text-align: center;
    margin-bottom: 4vh;
    margin-top: 1vh;
  }

@media screen and (max-width: 990px) {

    .formSection {
        width: 100%;
        /* margin-left: 2vw; */
    
    }
    
    .paymentSection{
        width: 100%;
        margin-left: 10%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }

    .registrationSection{
        display: block;
        padding-left: 15px;
        padding-right: 10px;
    }

    .form-control{
        width: 95%
    }

}

.fn {
    width: 85%;
    margin-bottom: 1.5vh;
}

.ln {
    width: 85%;
    margin-bottom: 1.5vh;
}

.email {
    width: 100%;
}

.firstname {
    margin-right: -2.7vw;
}

.gendersection {
    width: 30.5%;
    margin-right: 1.35vw;
}

.gendersection select {
    cursor: pointer;
}

.agesection {
    width: 30.5%;
    margin-right: 1.35vw;
}

.agesection select {
    cursor: pointer;
}

.sizesection {
    width: 30.5%;
}

.sizesection select {
    cursor: pointer;
}

@media screen and (max-width: 924px) {
    
    .paymentSection{
        width: 100%;
        margin-left: 8%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }
}

@media screen and (max-width: 899px) {
    
    .paymentSection{
        width: 100%;
        margin-left: 6%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }
}

@media screen and (max-width: 840px) {
    
    .paymentSection{
        width: 100%;
        margin-left: 4%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }
}

@media screen and (max-width: 820px) {
    
    .paymentSection{
        width: 100%;
        margin-left: 2%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }
}

@media screen and (max-width: 761px) {
    
    .paymentSection{
        width: 100%;
        margin-left: 0%;;
        margin-top: 5vh;
        margin-right: 0vw;
    }
}