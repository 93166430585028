.Gradient {
    
  background-image: var(--gradient);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8vh + 5vw);
  /* animation: slideInFromBottom 3s; */

}

.App-link {
  
  color: #61dafb;
  
}