.Impact {
    height: 100vh;
    margin-top: 17vh;
    padding-top: 8vh;
}

.impactStats {

    margin: 2vw;
    background-image: var(--gradient);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; 
    /* animation: slideInFromBottom 1s; */

}


.disclaimer {
    
    margin: 4vh;
    color: rgb(90, 90, 90);
    font-family: var(--norm);
    font-size: 2vh;

}

@media screen and (min-width: 990px) {

    .bottomStats {

        display: flex;
        justify-content: center;

    }

    .bottomStats .impactStats {

        width: 22vw;
        border-right: #363636 3px solid;
        margin: 0;
        padding: 4vh;
        
    }

    .bottomStats div:last-child {

        /* width: 22vw; */
        border-right: none;
        margin: 0;
        padding: 4vh;
        
    }
        
    .bottomStats h1 { font-size: 3vw; }

    .bottomStats h3 { font-size: 1.5vw; }

    .moneyRaised h1 { font-size: 20vw; }
    
    .moneyRaised h3 { font-size: 3.5vw; }

  }

  @media screen and (max-width: 990px) {

    .bottomStats { justify-content: center; }
    
    .bottomStats .impactStats { margin: 10vw 0; }

    .bottomStats h1{ font-size: 10vw; }

    .bottomStats h3 { font-size: 4vw; }
    
    .moneyRaised h1 { font-size: 20vw; }
    
    .moneyRaised h3 { font-size: 10vw; }

    .moneyRaised  {

        width: 75vw;
        border-bottom: #363636 3px solid;
        margin: 0;
        padding: 2vh;
        margin: auto;
        
    }

    .bottomStats .impactStats {

        width: 50vw;
        border-bottom: #363636 3px solid;
        margin: 0;
        padding: 2vh;
        margin: auto;
        
    }

    .bottomStats div:last-child {

        width: 90vw;
        border-bottom: none;
        margin: 0;
        padding: 2vh;
        margin: auto;
        
    }
    
  }

  