.button {

    border-radius: 100%;
    cursor: pointer; 
    margin: 0.05vh;
    margin-left: 0.25vh;
    margin-right: 0.25vh;
    padding: 0;

}   

.button:hover .icon { color: rgba(255, 255, 255, 0.7); }

.icon {
    
    width: 2vh;
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.25s ease-in-out;  

}