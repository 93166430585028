@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Lexend:wght@700&display=swap');

:root {

  --chunky: 'Lexend', sans-serif;
  --norm: 'Barlow Semi Condensed', sans-serif;
  --dark-grey: #0c0c0c;
  --primary: #5DE6FF;
  --secondary: #5ACAFF;
  --gradient: linear-gradient(135deg, var(--primary), var(--secondary));

  --z-spacing: 72vh;

}

@keyframes slideInFromBottom {

  0% { transform: translateY(1000%); }

  100% { transform: translateY(0); }

}

.App {

  text-align: center;
  font-family: var(--chunky);
  background-color: var(--dark-grey);
  overflow: hidden;
  width: 100vw;
  overflow-x: hidden;
  
}

.Shadowed {

  -webkit-filter: drop-shadow(0 0 5px #000000);
  filter: drop-shadow(0 0 5px #000000);

}

::-webkit-scrollbar { background: var(--dark-grey); width: 7px; }

::-webkit-scrollbar-track { border-radius: 10px; }

::-webkit-scrollbar-thumb { background: #45454579; border-radius: 10px; }

::-webkit-scrollbar-thumb:hover { background: #8d8d8d79; }