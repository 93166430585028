#team{
    height: 100vh;
  padding-top: 10vh;
}

.content {

    min-height: 100vh;
    margin-top: 2vh;
    margin-bottom: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
    
}

.carouselContainer {

    width: 20vh;
    height: 30vh;
    perspective: 1800px;

}
  
.carousel {

    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1s;
    justify-content: center;

}

.next, .prev {

  color: rgba(255, 255, 255, 0.65);
  position: absolute;
  cursor: pointer;
  font-size: 6vh;
  transition: all 0.3s; 

}

.next:hover, .prev:hover { transform: scale(1.2); }

.next { right: 37vw; }
.prev { left: 37vw; }

.honorableMentions {

    padding: 0 8.5vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: -15vh;
    /* color: rgb(90, 90, 90); */
    color: var(--secondary);
    font-family: var(--chunky);
    font-size: 2.25vh;

}

@media screen and (max-width: 990px) {

    .next, .prev {

        color: rgba(255, 255, 255, 0.65);
        /* position: absolute; */
        cursor: pointer;
        font-size: 6vh;
        transition: all 0.3s;
        /* top: 45vh; */
      
    }

    .next { right: 7vw; }
    .prev { left: 7vw; }

}