body{
  background-color:rgba(0, 0, 0, 0);
}

.myNavbar {

  padding: 3vh 0 3vh 0;
  z-index: 500;
  position: -webkit-sticky;
  /* background-image: linear-gradient(rgba(0, 0, 0, 1), rgb(29, 31, 32, 0)); */
}

.container { max-width: 100vw; }

.nav-item {

  margin-left: 2vh;
  margin-right: 2vh;
  font-size: 2vh;
  font-family: var(--norm);
  text-align: center;

}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.toggleBtn {

  top: 3.5vh;
  right: 2vh;
  position: fixed;
  z-index: 1000;
  border: none;

}

.navbar-brand img {

  position: fixed;
  top: 0vh;
  left: 2.5vh;
  width: calc(8vh + 1vw);
  height: calc(8vh + 1vw);
  z-index: 6;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
    
}

@media screen and (max-width: 991px) {

  #responsive-navbar-nav {
    left: 0;
  }

  .nav-link {
    text-align: right;
    font-size: 3vh;
  }

  .navItem {

    margin-left: auto;
    margin-right: 1vh;
    font-size: 2vh;
    width: fit-content;
  
  }

  #responsive-navbar-nav {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    /* right: 2vw; */
    bottom: 0;
    width: 100vw;
    padding: 20vw 2vw;
    transition: all .3s ease-in-out;
    outline: none;
  }

  .navItem { font-size: 2.5vh; }

  .navbar-collapse.collapsing {

    height: auto !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;

  }

  .navbar-collapse.show { visibility: visible; opacity: 1;}

  .container:has(.navbar-collapse):has(.show) .BW { opacity: 0; }

}

.animated-icon2 {

  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

}
  
.animated-icon2 span {

  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

}
  
.animated-icon2 span { background: #e3f2fd; }

.animated-icon2 span:nth-child(1) { top: 0px; }
  
.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) { top: 10px; }
  
.animated-icon2 span:nth-child(4) { top: 20px; }
  
.animated-icon2.open span:nth-child(1) {

  top: 11px;
  width: 0%;
  left: 50%;

}
  
.animated-icon2.open span:nth-child(2) {

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);

}
  
.animated-icon2.open span:nth-child(3) {

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);

}
  
.animated-icon2.open span:nth-child(4) {

  top: 11px;
  width: 0%;
  left: 50%;
  
}
