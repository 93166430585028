.paymentSection2{
    display: flex;
    /* float: right; */
    align-items: center;
    justify-content: left;
    /* background-color: red; */
    height: 30vh;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.paypalDiv{
    /* margin: 20px; */
    width: 30%;
    height: 200px;
    /* background-color: blue; */
    position: absolute;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: calc(-3vh - 9vw);
}

.donationSection{
    text-align: center;
    align-items: center;
    display: flex;
    padding-top: 25vh;
    margin-bottom: 20vh;
}
.formSection2{
    text-align: center;
    align-items: center;
    height: 60vh;
    width: 50vw;
    margin-left: 30px;
}
.registrationSectionHeader2{
    font-size: calc(3.5vh + 1.75vw);
    text-align: center;
    align-self: center;
}

.amount {
    font-size: calc(10vh + 10vw);
}

.donationValue {

    border-radius: 20px;
    margin: 1vh;
    padding: 2vh;
    width: calc(10vh + 10vw);
    height: clamp(5vh, 15vh, 30vh);
    font-size: clamp(0.5vh, 5vh, 8vh);
    background: linear-gradient(#141414, #1c1c1c) padding-box,
                linear-gradient(to right, var(--primary), #5dbcff) border-box;
    border-radius: 3vh;
    border: 4px solid transparent;
    transition: 0.25s ease all;

}

.labels2{
    color: var(--gradient);
    font-family: var(--barlow);
    text-align: center;
    font-size: calc(3.5vh + 1.75vw);
    margin-bottom: 1vh;
}

.donationValue:hover {
    border: 4px solid var(--primary);
    cursor: pointer;
    transform: scale(1.05);

}

.donationValue:focus {
    outline: none;
    border: 4px solid var(--primary);
    cursor: pointer;
    transform: scale(1.05);
    color: var(--primary);
    background: linear-gradient(#141414, #1c1c1c) padding-box,
                linear-gradient(to right, var(--primary), #5dbcff) border-box;
    
}

@media screen and (max-width: 991px) {
    .donationSection{
        text-align: center;
        align-items: center;
        display: inline;
    }
    .formSection2{
        width: 100%;
        margin-left: 0;
    }

    .paypalDiv{
        width: 50%;
        height: 200px;
        /* background-color: blue; */
        position: absolute;
        margin-left: 25%;
        margin-right: 25%;
    }

    .paymentSection2{
        margin-top: 5vh;
    }
    
}